import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { runAutoWiring } from './runners/runAutoWiring';
import { runRemoveWiring } from './runners/removeWiring';
import type { EditorAPI } from '@/editorAPI';

export * from './runners/removeWiring';

const COLORS_AUTOWIRING_HISTORY_LABEL = 'colorsAutoWiring';
const WIRING_REMOVAL_LABEL = 'autowiringRemoval';

const { NOTIFICATIONS } = constants;

const showNotification = (
  editorAPI: EditorAPI,
  message: string,
  error?: Error,
) => {
  if (error) {
    console.error(`[${message}]:`, error);
  }

  editorAPI.store.dispatch(
    stateManagement.notifications.actions.showUserActionNotification({
      message: error
        ? `${message}. The next error happened: "${error.message}"`
        : message,
      shouldTranslate: false,
      type: error ? NOTIFICATIONS.TYPES.ERROR : NOTIFICATIONS.TYPES.SUCCESS,
    }),
  );
};

export const INTERNAL_undoAutoWiring = async (editorAPI: EditorAPI) => {
  // the feature is just for internal use, so keeping in window is ok
  (window as any).__advanced_wiring_done__ = false;

  await editorAPI.history.performUndoUntilLabel(
    COLORS_AUTOWIRING_HISTORY_LABEL,
    true,
  );
};

export const INTERNAL_applyAutoWiring = async (editorAPI: EditorAPI) => {
  try {
    editorAPI.panelHelpers.openProgressBar(
      {
        title: 'Applying colors auto wiring',
        totalSteps: 1,
        currentStep: 0,
        taskDisplayName: 'In progress...',
      },
      true,
    );

    editorAPI.history.add(COLORS_AUTOWIRING_HISTORY_LABEL);

    await Promise.allSettled([
      runAutoWiring(editorAPI),
      new Promise((resolve) => setTimeout(resolve, 500)),
    ]).then(([res]) => {
      if (res.status === 'rejected') throw res.reason;
    });

    editorAPI.panelHelpers.updateProgressBar(1, 'Done!');

    // the feature is just for internal use, so keeping in window is ok
    (window as any).__advanced_wiring_done__ = true;
  } catch (e) {
    await INTERNAL_undoAutoWiring(editorAPI);

    showNotification(editorAPI, 'Autowiring failed', e as Error);
  } finally {
    editorAPI.panelHelpers.closeProgressBar();
  }
};

export const INTERNAL_removeAllWiring = async (editorAPI: EditorAPI) => {
  editorAPI.history.add(WIRING_REMOVAL_LABEL);

  try {
    await runRemoveWiring(editorAPI);
  } catch (e) {
    editorAPI.history.performUndoUntilLabel(WIRING_REMOVAL_LABEL, true);

    showNotification(editorAPI, 'Wiring removal failed', e as Error);
    return;
  }

  showNotification(editorAPI, 'Advanced wiring removal for all colors done!');
};
