import type { EditorAPI } from '@/editorAPI';

import { ADVANCED_COLORS_MAPPING } from '../colors/constants';
import type { ColorName } from '../colors/types';

const THEME_COLORS_MAPPING = {
  MAIN_1: [36, 11],
  MAIN_2: [37, 15],
  SHADE_1: [38, 12],
  SHADE_2: [39, 13],
  SHADE_3: [40, 14],
  SECONDARY_1: [41, 18],
  SECONDARY_2: [42, 15],
  SECONDARY_3: [43, 13],
  SECONDARY_4: [44, 11],
};

export const expandColorPalette = async (editorAPI: EditorAPI) => {
  const palette = editorAPI.theme.colors.getAll();

  // Put correct hexes into theme colors
  const themeColors = Object.values(THEME_COLORS_MAPPING).reduce(
    (acc, [themeColor, fromColor]) => ({
      [`color_${themeColor}`]: palette[`color_${fromColor}` as ColorName],
      ...acc,
    }),
    {},
  );

  editorAPI.theme.colors.update({
    ...themeColors,
    ...ADVANCED_COLORS_MAPPING,
    // color_13 is used by some TPAs and cannot be changed by a user
    // see https://jira.wixpress.com/browse/WEED-29055
    color_13: 'color_39',
  });

  await editorAPI.waitForChangesAppliedAsync();
};
