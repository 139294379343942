import _ from 'lodash';
import { hexToRgb, rgbToHex } from './colorConversions';
import type { RGBColorObject } from '../colors/types';

const fixValue = (value: number) => {
  if (value > 255) return 255;
  if (value < 0) return 0;
  return Math.round(value);
};

const RGB_COLORS = ['red', 'green', 'blue'] as const;

const getShadeRgb = (
  darkRgb: RGBColorObject,
  lightRgb: RGBColorObject,
  darkRatio: number,
) =>
  _.fromPairs(
    RGB_COLORS.map((color) => [
      color,
      fixValue(darkRatio * darkRgb[color] + (1 - darkRatio) * lightRgb[color]),
    ]),
  );

const SHADES_RATIOS = {
  shade1: 0.25,
  shade2: 0.5,
  shade3: 0.75,
};

export const generateShadeColors = (hexLight: string, hexDark: string) => {
  const lightRgb = hexToRgb(hexLight);
  const darkRgb = hexToRgb(hexDark);

  return _.mapValues(SHADES_RATIOS, (ratio) =>
    rgbToHex(getShadeRgb(darkRgb, lightRgb, ratio)),
  );
};
