import type { ColorPalette, ColorName } from './types';
import { utils as themeUtils } from '@/theme';

import { COLOR_ROLES } from './constants';
import { getColorNameByRole, getColorValueByRole } from './utils';

export const areBaseColorsAccessible = (palette: ColorPalette) => {
  const color1 = getColorValueByRole(palette, COLOR_ROLES.MAIN_1);
  const color2 = getColorValueByRole(palette, COLOR_ROLES.MAIN_2);

  return themeUtils.isAccessible(color1, color2, 4.5);
};

const textToBackgroundMap = [
  {
    text: COLOR_ROLES.PRIMARY_TEXT,
    bg: [COLOR_ROLES.BACKGROUND, COLOR_ROLES.SECONDARY_BACKGROUND],
    optimalContrast: 4.5,
  },
  {
    text: COLOR_ROLES.SECONDARY_TEXT,
    bg: [COLOR_ROLES.BACKGROUND], //Secondary text should not be checked for accessibility with secondary BG
    optimalContrast: 4.5,
  },
  {
    text: COLOR_ROLES.LINK,
    bg: [COLOR_ROLES.BACKGROUND],
    optimalContrast: 4.5,
  },
  {
    text: COLOR_ROLES.TITLE,
    bg: [COLOR_ROLES.BACKGROUND, COLOR_ROLES.SECONDARY_BACKGROUND],
    optimalContrast: 3,
  },
  {
    text: COLOR_ROLES.SUBTITLE,
    bg: [COLOR_ROLES.BACKGROUND, COLOR_ROLES.SECONDARY_BACKGROUND],
    optimalContrast: 3,
  },
  {
    text: COLOR_ROLES.PRIMARY_BUTTON_TEXT,
    bg: [COLOR_ROLES.PRIMARY_BUTTON_FILL],
    optimalContrast: 4.5,
  },
  {
    text: COLOR_ROLES.SECONDARY_BUTTON_TEXT,
    bg: [COLOR_ROLES.SECONDARY_BUTTON_FILL],
    optimalContrast: 4.5,
  },
];

const getConflictColorRoles = (
  bgColors: COLOR_ROLES[],
  textColor: COLOR_ROLES,
  optimalContrast: number,
  palette: ColorPalette,
) => {
  const conflictColorRoles = bgColors.filter(
    (role) =>
      !themeUtils.isAccessible(
        getColorValueByRole(palette, textColor),
        getColorValueByRole(palette, role),
        optimalContrast,
      ),
  );

  return conflictColorRoles;
};

export const getColorsA11yIssues = (palette: ColorPalette) => {
  return textToBackgroundMap.reduce(
    (acc, val) => {
      const textColorName = getColorNameByRole(val.text);

      acc[textColorName] = {
        conflictColorRoles: getConflictColorRoles(
          val.bg,
          val.text,
          val.optimalContrast,
          palette,
        ),
      };

      return acc;
    },
    {} as Record<ColorName, { conflictColorRoles: string[] }>,
  );
};
