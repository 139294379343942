import _ from 'lodash';
import experiment from 'experiment';

import type { FontId } from '@wix/document-services-types';

import type { EditorAPI } from '@/editorAPI';
import * as core from '@/core';
import { templatesFedops as fedops } from './fedops';
import constants from '@/constants';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { menuComponentTypes } from '@/menu/constants';
import { ErrorReporter } from '@wix/editor-error-reporter';

import { COLOR_ROLES } from '../colors/constants';
import type { ColorName, LinkedColors } from '../colors/types';
import { getColorNameByRole, unwrapColors } from '../colors/utils';
import { getColorsA11yIssues } from '../colors/a11y';

import {
  getMostUsedColors,
  generatePaletteToUpdateAccentColors,
  saveCustomColors,
  saveSiteMigratedFlag,
  getRelinkedAdvancedColors,
  setPageBg,
} from './utils';
import { expandColorPalette } from './expandColorPalette';
import { applyAutoWiring, runRemoveWiring } from './advancedWiring';

const getHeaderMenuColors = (editorAPI: EditorAPI) => {
  const headerComp = editorAPI.siteSegments.getHeader();

  const menuComponents = Object.values(menuComponentTypes).flatMap((type) =>
    editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
      type,
      headerComp,
    ),
  );

  const menuStyles = editorAPI.components.style.get(menuComponents[0])?.style
    ?.properties;

  return unwrapColors([menuStyles?.txt, menuStyles?.txth, menuStyles?.txts]);
};

const migrateAccentColors = async (editorAPI: EditorAPI) => {
  const palette = editorAPI.theme.colors.getAll();
  const textStyles = editorAPI.theme.textThemes.getAll();

  let dynamicAccentColors = [
    getColorNameByRole(COLOR_ROLES.SECONDARY_2),
    getColorNameByRole(COLOR_ROLES.SECONDARY_3),
    getColorNameByRole(COLOR_ROLES.SECONDARY_4),
  ];
  let advancedColors: LinkedColors = {};

  const mostUsedColors = getMostUsedColors(editorAPI, {
    filterColorAppearingOnce: true,
  });
  // eslint-disable-next-line @wix/santa/no-falsy-experiment
  if (!experiment.isOpen('se_removeLinkColorsOverwrightIfNotExists')) {
    const buttonStyles = core.styleManager.getUniqueThemeStyleList(
      editorAPI.theme,
      editorAPI.components,
      constants.COMP_TYPES.SITE_BUTTON,
    );
    const isColor18InButtons = buttonStyles
      .flatMap((btn) => [btn.style.properties.bg, btn.style.properties.brd])
      .includes('color_18');
    const isColor18InTextStyles = Object.values(textStyles)
      .map((style) => unwrapColors(style.color))
      .includes('color_18');
    const isColor18InHeaderMenu =
      getHeaderMenuColors(editorAPI).includes('color_18');

    if (
      !isColor18InButtons &&
      !isColor18InTextStyles &&
      !isColor18InHeaderMenu
    ) {
      dynamicAccentColors = [
        getColorNameByRole(COLOR_ROLES.SECONDARY_1),
        getColorNameByRole(COLOR_ROLES.SECONDARY_2),
        getColorNameByRole(COLOR_ROLES.SECONDARY_3),
        getColorNameByRole(COLOR_ROLES.SECONDARY_4),
      ];
      // relink advanced colors from 41 to 37 because 41 will be filled with new color
      advancedColors = getRelinkedAdvancedColors(
        editorAPI,
        'color_41',
        'color_37',
      );
    }
  }

  const accentColors = mostUsedColors.slice(0, dynamicAccentColors.length);
  const userColors = mostUsedColors.slice(dynamicAccentColors.length);

  const paletteToUpdate = generatePaletteToUpdateAccentColors(
    accentColors,
    palette,
    dynamicAccentColors,
  );

  editorAPI.theme.colors.update({ ...paletteToUpdate, ...advancedColors });

  saveCustomColors(
    editorAPI,
    userColors.map((color) => palette[color]),
  );

  await editorAPI.waitForChangesAppliedAsync();
};

export const updateTextStyles = async (editorAPI: EditorAPI) => {
  const getFontColor = (font: string) =>
    unwrapColors(editorAPI.theme.textThemes.get(font as FontId).color);

  const shouldDisableTextColorsUpdate = experiment.isOpen(
    'se_newColorPaletteDisableTextColorsUpdate',
  );

  const palette = editorAPI.theme.colors.getAll();
  const visibleThemeColors = editorAPI.theme.colors.getVisibleThemeColorsKeys();

  const {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p: p1,
    div: p2,
    address: p3,
  } = constants.TEXT_CONTROLS.TEXT_THEMES_STYLES_MAP;

  const h1Color = getFontColor(h1.cssClass);
  const h2Color = getFontColor(h2.cssClass);
  const p2Color = getFontColor(p2.cssClass);

  const colorsToUpdate = {} as Record<ColorName, ColorName>;

  if (visibleThemeColors.includes(h2Color)) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.TITLE)] = h2Color;
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.SUBTITLE)] = h2Color;
  }

  if (!shouldDisableTextColorsUpdate && visibleThemeColors.includes(p2Color)) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.PRIMARY_TEXT)] = p2Color;
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.SECONDARY_TEXT)] = p2Color;
  }

  const h3h6Colors = [h3, h4, h5, h6].map(({ cssClass }) =>
    getFontColor(cssClass),
  );
  const p1p3Colors = [p1, p3].map(({ cssClass }) => getFontColor(cssClass));

  const differentHeadingColor = h3h6Colors.find(
    (color) => color !== h1Color && color !== h2Color,
  );
  const differentParagraphColor = p1p3Colors.find((color) => color !== p2Color);

  if (visibleThemeColors.includes(differentHeadingColor)) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.SUBTITLE)] =
      differentHeadingColor;
  }
  if (
    !shouldDisableTextColorsUpdate &&
    visibleThemeColors.includes(differentParagraphColor)
  ) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.SECONDARY_TEXT)] =
      differentParagraphColor;
  }

  if (shouldDisableTextColorsUpdate) {
    // specific override under this experiment, if it success need to be chnaged mapping in COLOR_ROLE_TO_COLOR_NAME_MAP
    // https://jira.wixpress.com/browse/WEED-29738
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.LINE)] = getColorNameByRole(
      COLOR_ROLES.MAIN_2,
    );
  }

  const a11yIssues = getColorsA11yIssues({
    ...palette,
    ..._.mapValues(colorsToUpdate, (linkedTo) => palette[linkedTo]),
  });
  const colorToSet = _.mapValues(colorsToUpdate, (val, key) => {
    if (
      shouldDisableTextColorsUpdate &&
      (key === getColorNameByRole(COLOR_ROLES.PRIMARY_TEXT) ||
        key === getColorNameByRole(COLOR_ROLES.SUBTITLE))
    ) {
      return val;
    }
    return a11yIssues[key as ColorName]?.conflictColorRoles.length > 0
      ? getColorNameByRole(COLOR_ROLES.MAIN_2)
      : val;
  });
  editorAPI.theme.colors.update(colorToSet);

  await editorAPI.waitForChangesAppliedAsync();
};

export const runTemplatesMigration = async (editorAPI: EditorAPI) => {
  try {
    fedops.palleteMigration.start();

    await expandColorPalette(editorAPI);
    fedops.setAccents.start();
    await migrateAccentColors(editorAPI);
    fedops.setAccents.end();

    // autowiring is quite long, so we want to have new ui available sooner
    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();

    fedops.removeWiring.start();
    await runRemoveWiring(editorAPI);
    fedops.removeWiring.end();

    // eslint-disable-next-line @wix/santa/no-falsy-experiment
    if (!experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')) {
      await setPageBg(editorAPI);
    }

    await updateTextStyles(editorAPI);

    await applyAutoWiring(editorAPI);
    if (experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')) {
      await setPageBg(editorAPI);
    }

    fedops.palleteMigration.end();
  } catch (e: MaybeError) {
    console.log('[NEW COLOR PALETTE] Templates migration failed:', e.message);
    ErrorReporter.captureException(e, {
      tags: { newColorPaletteMigrationTemplatesFlow: true },
    });
  }
};
