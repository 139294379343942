import { scFlowFedops as fedops } from './fedops';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';

import { getColorNameByRole, getShadesFromBaseColors } from '../colors/utils';
import type { ColorName, ColorPalette, LinkedColors } from '../colors/types';
import { COLOR_ROLES } from '../colors/constants';
import { applyAutoWiring } from './advancedWiring';
import { expandColorPalette } from './expandColorPalette';
import {
  getMostUsedColors,
  generatePaletteToUpdateAccentColors,
  getPrimaryPageBgColor,
  saveSiteMigratedFlag,
  saveCustomColors,
  getRelinkedAdvancedColors,
} from './utils';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { hexToRgb, getColorDelta } from '../utils';

const getFooterColor = (editorAPI: EditorAPI) => {
  const footerComp = editorAPI.siteSegments.getFooter();

  if (!footerComp?.id) return;

  const footerTextComp =
    editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
      constants.COMP_TYPES.TEXT,
      footerComp,
    )[0];

  if (!footerTextComp) return;

  const html = editorAPI.components.data.get(footerTextComp).text;
  const color = html.match(/color_\d{1,2}/)?.[0];

  return editorAPI.theme.colors.get(color);
};

const updateBaseColors = async (editorAPI: EditorAPI) => {
  const footerTextColor = getFooterColor(editorAPI);
  const bgColor = editorAPI.theme.colors.get(getPrimaryPageBgColor(editorAPI));

  if (!footerTextColor || !bgColor) return;

  const baseColors = {
    color_36: bgColor,
    color_37: footerTextColor,
  };

  const shades = getShadesFromBaseColors(baseColors);

  editorAPI.theme.colors.update({
    ...baseColors,
    ...shades,
  });

  await editorAPI.waitForChangesAppliedAsync();
};

// Calculate if most used colors contain shades of base colors
// If yes - remove them from accent and put into shades
const getShadesFromMostUsedColors = (
  accentColors: ColorName[],
  palette: ColorPalette,
) => {
  const getMostSimilarAccentTo = (colorName: ColorName) =>
    accentColors.reduce(
      (acc, accentColor) => {
        const hex = palette[accentColor];
        const delta = getColorDelta(
          hexToRgb(hex),
          hexToRgb(palette[colorName]),
        );
        return delta >= acc.delta
          ? { hex, delta, colorName: accentColor }
          : acc;
      },
      { hex: null, delta: 90, colorName: null as ColorName },
    );

  const leftShade = getMostSimilarAccentTo('color_36');
  const rightShade = getMostSimilarAccentTo('color_37');

  const shadesToUpdate: ColorPalette = {};
  if (leftShade.colorName) {
    shadesToUpdate.color_38 = leftShade.hex;
    shadesToUpdate[leftShade.colorName] = 'color_38';
  }
  if (rightShade.colorName) {
    shadesToUpdate.color_40 = rightShade.hex;
    shadesToUpdate[rightShade.colorName] = 'color_40';
  }

  const filteredAccents = accentColors.filter(
    (color) =>
      palette[color] !== leftShade.hex && palette[color] !== rightShade.hex,
  );

  return { shadesToUpdate, filteredAccents };
};

const migrateAccentColors = async (editorAPI: EditorAPI) => {
  const dynamicAccentColors = [
    getColorNameByRole(COLOR_ROLES.SECONDARY_1),
    getColorNameByRole(COLOR_ROLES.SECONDARY_2),
    getColorNameByRole(COLOR_ROLES.SECONDARY_3),
    getColorNameByRole(COLOR_ROLES.SECONDARY_4),
  ];
  const palette = editorAPI.theme.colors.getAll();

  const mostUsedColors = getMostUsedColors(editorAPI);
  let advancedColors: LinkedColors = {};

  const isColor18InMostUsed = !!mostUsedColors.find(
    (color) => palette[color] === palette.color_18,
  );
  if (isColor18InMostUsed) {
    // Make color_18 first, so that is is put in 41
    mostUsedColors.sort((a) => (palette[a] === palette.color_18 ? -1 : 0));
  } else {
    // relink advanced colors from 41 to 37 because 41 will be filled with new color
    advancedColors = getRelinkedAdvancedColors(
      editorAPI,
      'color_41',
      'color_37',
    );
  }

  const { filteredAccents, shadesToUpdate } = getShadesFromMostUsedColors(
    mostUsedColors,
    palette,
  );

  const accentColors = filteredAccents.slice(0, 3);
  const userColors = filteredAccents.slice(3);

  const paletteToUpdate = generatePaletteToUpdateAccentColors(
    accentColors,
    palette,
    dynamicAccentColors,
  );

  editorAPI.theme.colors.update({
    ...paletteToUpdate,
    ...advancedColors,
    ...shadesToUpdate,
  });

  await editorAPI.waitForChangesAppliedAsync();
  saveCustomColors(
    editorAPI,
    userColors.map((color) => palette[color]),
  );
};

export const migrateColorsAfterSc = async (editorAPI: EditorAPI) => {
  try {
    fedops.palleteMigration.start();

    // when we update base colors, we need to have correct advanced color wiring set
    fedops.setColors.start();

    await expandColorPalette(editorAPI);
    await updateBaseColors(editorAPI);

    // accent colors have to be set last so that
    // it doesn't count colors already present in base colors
    await migrateAccentColors(editorAPI);

    fedops.setColors.end();

    // autowiring is quite long, so we want to have new ui available sooner
    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();

    await applyAutoWiring(editorAPI);

    fedops.palleteMigration.end();
  } catch (e: MaybeError) {
    console.log('[NEW COLOR PALETTE] SC migration failed:', e.message);

    ErrorReporter.captureException(e, {
      tags: { newColorPaletteMigrationSCFlow: true },
    });
  }
};
