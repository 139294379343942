import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';

import {
  type ColorName,
  type LinkedColors,
  isTextCompData,
  extractColorFromClassAttr,
  TEXT_COLOR_CLASS_REGEX,
  PRESETS,
  ACCENT_COLOR_REGEX,
  COLOR_REGEX,
  type LinkedColorValue,
} from '../../../colors';
import {
  replaceColorsInStyleProps,
  wrapColorWithClassAttr,
  type ColorReplacer,
} from '../utils';
import { COMPS_WIRING_CONFIG, type CompType } from './config/compsWiringConfig';
import type { CompRef } from '@wix/document-services-types';

const getLinkedToColor = (
  colorName: ColorName,
  linkedColors: LinkedColors,
): LinkedColorValue | null => {
  return ACCENT_COLOR_REGEX.test(colorName as string)
    ? null
    : linkedColors[colorName];
};

export const updateFontsData = (
  editorAPI: EditorAPI,
  linkedColors: LinkedColors,
) => {
  // Need to be changed to use the new API and well tested after that
  // https://github.com/wix-private/santa-editor/issues/48298
  // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
  const currentFonts = editorAPI.theme.fonts.getAll();

  // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
  editorAPI.theme.fonts.update(
    _.mapValues(currentFonts, (fontValue: string) =>
      fontValue.replace(COLOR_REGEX, (colorName: string) => {
        const linkedTo = getLinkedToColor(
          colorName as ColorName,
          linkedColors,
        ) as string;
        return linkedTo || colorName;
      }),
    ),
  );
};

export const updateComponentData = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  linkedColors: LinkedColors,
) => {
  for (const compFunc of PRESETS) {
    const compApi = compFunc.getApi(editorAPI);

    const compData = compApi.get(compRef);
    let shouldUpdateComp = false;

    if (!_.isEmpty(compData)) {
      compFunc.colorPaths.forEach((colorPath) => {
        const pathValue = _.get(compData, colorPath);

        if (!pathValue) return;

        let shouldUpdateField = false;

        const isTextFlow = isTextCompData(compData);

        const replacer = (colorMatch: string) => {
          const cleanColorName = isTextFlow
            ? extractColorFromClassAttr(colorMatch)
            : colorMatch;

          const linkedTo: LinkedColorValue = getLinkedToColor(
            cleanColorName as ColorName,
            linkedColors,
          );

          if (!linkedTo) return colorMatch;

          shouldUpdateField = true;

          return isTextFlow
            ? wrapColorWithClassAttr(linkedTo as string)
            : linkedTo;
        };

        const newStyleProps = replaceColorsInStyleProps(
          pathValue,
          replacer as ColorReplacer,
          isTextFlow ? TEXT_COLOR_CLASS_REGEX : COLOR_REGEX,
        );

        if (shouldUpdateField) {
          _.set(compData, colorPath, newStyleProps);
          shouldUpdateComp = true;
        }
      });

      if (shouldUpdateComp) {
        compApi.update(compRef, compData);
      }
    }
  }
};

export const performUnWiring = (
  editorAPI: EditorAPI,
  linkedColors: LinkedColors,
) => {
  const allComps = editorAPI.components.getAllComponents();

  for (const compRef of allComps) {
    const compType = editorAPI.components.getType(compRef) as CompType;
    if (COMPS_WIRING_CONFIG.hasOwnProperty(compType) === false) {
      continue;
    }

    updateComponentData(editorAPI, compRef, linkedColors);
  }
};

export const runRemoveWiring = async (editorAPI: EditorAPI) => {
  const linkedColors = editorAPI.theme.colors.getAllLinkedColors() ?? {};

  performUnWiring(editorAPI, linkedColors);
  updateFontsData(editorAPI, linkedColors);

  await editorAPI.waitForChangesAppliedAsync();
};
