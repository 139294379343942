import type { EditorAPI } from '@/editorAPI';
import { exisitingSitesFlowFedops as fedops } from './fedops';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { COLOR_ROLES } from '../colors/constants';
import type { LinkedColors } from '../colors/types';
import { getColorNameByRole } from '../colors/utils';
import { applyAutoWiring, runRemoveWiring } from './advancedWiring';
import { updateTextStyles } from './templatesMigration';
import {
  getMostUsedColors,
  generatePaletteToUpdateAccentColors,
  saveCustomColors,
  saveSiteMigratedFlag,
} from './utils';
import { expandColorPalette } from './expandColorPalette';

const migrateAccentColors = async (editorAPI: EditorAPI) => {
  const palette = editorAPI.theme.colors.getAll();

  const dynamicAccentColors = [
    getColorNameByRole(COLOR_ROLES.SECONDARY_2),
    getColorNameByRole(COLOR_ROLES.SECONDARY_3),
    getColorNameByRole(COLOR_ROLES.SECONDARY_4),
  ];
  const advancedColors: LinkedColors = {};

  const mostUsedColors = getMostUsedColors(editorAPI, {
    filterColorAppearingOnce: true,
  });

  const accentColors = mostUsedColors.slice(0, dynamicAccentColors.length);
  const userColors = mostUsedColors.slice(dynamicAccentColors.length);

  const paletteToUpdate = generatePaletteToUpdateAccentColors(
    accentColors,
    palette,
    dynamicAccentColors,
  );

  editorAPI.theme.colors.update({ ...paletteToUpdate, ...advancedColors });

  saveCustomColors(
    editorAPI,
    userColors.map((color) => palette[color]),
  );

  await editorAPI.waitForChangesAppliedAsync();
};

export const runExistingSitesMigration = async (editorAPI: EditorAPI) => {
  try {
    fedops.palleteMigration.start();

    await expandColorPalette(editorAPI);
    fedops.setAccents.start();
    await migrateAccentColors(editorAPI);
    fedops.setAccents.end();

    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();
    fedops.removeWiring.start();
    await runRemoveWiring(editorAPI);
    fedops.removeWiring.end();

    await updateTextStyles(editorAPI);

    await applyAutoWiring(editorAPI);

    fedops.palleteMigration.end();
  } catch (e: MaybeError) {
    console.log(
      '[NEW COLOR PALETTE] Existing sites migration failed:',
      e.message,
    );
    ErrorReporter.captureException(e, {
      tags: { newColorPaletteExistingSitesMigrationFlow: true },
    });
  }
};
