import _ from 'lodash';

import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';

import {
  OLD_PALETTE_DEPRECATED_COLORS,
  EMPTY_COLOR_NAME_VALUE,
  MAIN_THEME_COLORS,
  TEXT_COLOR_CLASS_REGEX,
  COLOR_REGEX,
  PRESETS,
  COLOR_ROLES,
} from '../colors/constants';
import type { ColorName, ColorPalette, LinkedColors } from '../colors/types';
import {
  isTextCompData,
  extractColorFromClassAttr,
  unwrapColors,
  getColorValueByRole,
  getColorNameByRole,
} from '../colors/utils';
import { utils as themeUtils } from '@/theme';
import experiment from 'experiment';
import type { CompRef } from '@wix/document-services-types';

export const saveCustomColors = (
  editorAPI: EditorAPI,
  customColors: string[],
) => {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED,
      customColors,
    ),
  );
};

export const saveSiteMigratedFlag = (editorAPI: EditorAPI) => {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      constants.USER_PREFS.THEME.COLORS.IS_MIGRATED,
      true,
    ),
  );
};

export const saveSiteColorsSyncFlag = (editorAPI: EditorAPI) => {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      constants.USER_PREFS.THEME.COLORS.ARE_COLORS_SYNCHRONISED,
      true,
    ),
  );
};

export const sortByMostUsed = (
  editorAPI: EditorAPI,
  allColors: ColorName[],
  options?: { filterColorAppearingOnce?: boolean },
) => {
  const relevantColors = _.difference(allColors, OLD_PALETTE_DEPRECATED_COLORS);

  const frequencyMap = relevantColors.reduce(
    (colorsToUsageMap: Record<ColorName, number>, colorName: ColorName) => {
      if (colorsToUsageMap[colorName]) {
        colorsToUsageMap[colorName] += 1;
      } else {
        colorsToUsageMap[colorName] = 1;
      }
      return colorsToUsageMap;
    },
    {} as Record<ColorName, number>,
  );
  const mostUsedColorOrdered = relevantColors.sort((colorValue, next) => {
    const v = frequencyMap[next] - frequencyMap[colorValue];
    if (v === 0) {
      return 1;
    }
    return v;
  });

  const uniqColors = _.uniq(mostUsedColorOrdered);

  if (options?.filterColorAppearingOnce) {
    const pageBg = getPrimaryPageBgColor(editorAPI);
    return uniqColors.filter(
      (color) => frequencyMap[color] > 1 || color === pageBg,
    );
  }

  return uniqColors;
};

export const scanPathsAndGetColors = (
  compData: Object,
  colorPaths: string[][],
  colorsAccumulator: ColorName[] = [],
): ColorName[] => {
  const isTextFlow = isTextCompData(compData);

  return colorPaths.reduce(
    (result: ColorName[], colorPath: string[]) => {
      const colorProps = _.get(compData, colorPath, {});

      if (_.isEmpty(colorProps)) {
        return result;
      }

      const colorsMatches = getAllMatchesFromStyleProps(
        colorProps,
        isTextFlow ? TEXT_COLOR_CLASS_REGEX : COLOR_REGEX,
      ) as ColorName[];

      const colorsToPush = isTextFlow
        ? colorsMatches.map<ColorName>(extractColorFromClassAttr)
        : colorsMatches;

      return [...result, ...colorsToPush];
    },
    [...colorsAccumulator],
  );
};

export const getAllColors = (editorAPI: EditorAPI): ColorName[] => {
  const allComps = editorAPI.components.getAllComponents();

  let colors: ColorName[] = [];

  for (const compRef of allComps) {
    for (const compFunc of PRESETS) {
      const compData = compFunc.getApi(editorAPI).get(compRef);
      if (!_.isEmpty(compData)) {
        colors = [
          ...scanPathsAndGetColors(compData, compFunc.colorPaths, colors),
        ];
      }
    }
  }

  return colors;
};

export const generatePaletteToUpdateAccentColors = (
  accentColors: ColorName[],
  palette: ColorPalette,
  dynamicAccentColors: ColorName[],
) => {
  return dynamicAccentColors.reduce((acc, accentColorToUpdate, idx) => {
    const colorName = accentColors[idx];
    const hex = palette[colorName];

    if (colorName) {
      acc[accentColorToUpdate] = hex;
      acc[colorName] = accentColorToUpdate;
    } else {
      acc[dynamicAccentColors[idx]] = EMPTY_COLOR_NAME_VALUE;
    }
    return acc;
  }, {} as ColorPalette);
};

/**
 * Filter out colors that are already present in theme colors
 */
export const omitThemeColors = (
  mostUsedColor: ColorName[],
  palette: ColorPalette,
) => {
  return mostUsedColor.filter((currentColorName) => {
    let isUniq = true;
    for (const colorName of MAIN_THEME_COLORS) {
      if (
        palette[currentColorName] === palette[colorName] &&
        colorName !== currentColorName
      ) {
        isUniq = false;
        break;
      }
    }
    return isUniq;
  }, []);
};

/**
 * Recursively finds all regex matches in style props
 */
export const getAllMatchesFromStyleProps = (
  props: any,
  searchValue: string | RegExp,
): string[] => {
  if (typeof props === 'object' && props !== null) {
    const mapFunction = (innerProps: any) =>
      getAllMatchesFromStyleProps(innerProps, searchValue);

    const valuesArray = Array.isArray(props) ? props : Object.values(props);

    return valuesArray.flatMap(mapFunction);
  } else if (typeof props === 'string') {
    return props.match(new RegExp(searchValue, 'g')) || [];
  }
  return [];
};

/**
 * Scan through all components on site and sort colors depends on usage firts 3 components is accents
 */
export const getMostUsedColors = (
  editorAPI: EditorAPI,
  options?: { filterColorAppearingOnce?: boolean },
) => {
  const palette = editorAPI.theme.colors.getAll();

  const allColors = getAllColors(editorAPI);
  const mostUsedColors = sortByMostUsed(editorAPI, allColors, options);
  return omitThemeColors(mostUsedColors, palette);
};

const getPageBgStyleData = (editorAPI: EditorAPI) => {
  const deviceType = stateManagement.mobile.selectors.getDeviceType(
    editorAPI.store.getState(),
  );

  const pageId = editorAPI.pages.getPrimaryPageId();
  return editorAPI.pages.background.get(pageId, deviceType);
};

export const getPrimaryPageBgColor = (editorAPI: EditorAPI) => {
  const bg = getPageBgStyleData(editorAPI);
  const color = bg.ref.color;

  if (!color) return;

  return unwrapColors(color);
};

export const updateColorInPageComponent = async (
  editorAPI: EditorAPI,
  newPageBgColor: ColorName,
) => {
  const type = stateManagement.mobile.selectors.getDeviceType(
    editorAPI.store.getState(),
  );

  const pageId = editorAPI.pages.getPrimaryPageId();

  const pageBgData = getPageBgStyleData(editorAPI);

  _.set(pageBgData, 'ref.color', `{${newPageBgColor}}`);
  editorAPI.pages.background.update(pageId, pageBgData, type);
};

const findMatchingAccentColor = (editorAPI: EditorAPI, color: string) => {
  const palette = editorAPI.theme.colors.getAll();
  const accentColors = _.pickBy(palette, (_, key) =>
    editorAPI.theme.colors.isAccentColor(key as ColorName),
  );

  return _.findKey(accentColors, (hex) => hex === color);
};

const getMatchingAccent = (editorAPI: EditorAPI, pageBgColor: string) => {
  const matchingAccent = findMatchingAccentColor(editorAPI, pageBgColor);

  if (
    matchingAccent &&
    !editorAPI.theme.colors.isAccentColorEmpty(matchingAccent as ColorName)
  ) {
    return matchingAccent;
  }
  return pageBgColor;
};

const findMatchingPageBGColor = (editorAPI: EditorAPI) => {
  const pageBgColor = editorAPI.theme.colors.get(
    getPrimaryPageBgColor(editorAPI),
  );

  const base1 = getColorValueByRole(
    editorAPI.theme.colors.getAll(),
    COLOR_ROLES.MAIN_1,
  );

  if (pageBgColor !== base1) {
    return getMatchingAccent(editorAPI, pageBgColor);
  }
  return pageBgColor;
};

export const setPageBg = async (editorAPI: EditorAPI) => {
  const pageBgColor = findMatchingPageBGColor(editorAPI);
  if (experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')) {
    const palette = editorAPI.theme.colors.getAll();
    /** We need to check if page background color acessible to text color before set it as primary background to advanced colors */
    const isNewBgColorAcessibleWithText = themeUtils.isAccessible(
      pageBgColor,
      palette[getColorNameByRole(COLOR_ROLES.PRIMARY_TEXT)],
    );
    if (isNewBgColorAcessibleWithText) {
      editorAPI.theme.colors.update({ color_11: pageBgColor });
    }
  } else {
    editorAPI.theme.colors.update({ color_11: pageBgColor });
  }

  await editorAPI.waitForChangesAppliedAsync();
};

/*
 * Get object to relink advanced colors from one color to another
 */
export const getRelinkedAdvancedColors = (
  editorAPI: EditorAPI,
  fromColor: ColorName,
  toColor: ColorName,
): LinkedColors => {
  const linkedColors = editorAPI.theme.colors.findLinkedColors(
    fromColor,
    editorAPI.theme.colors.getAllLinkedColors(),
  );

  const colorPaletteToUpdate = linkedColors.reduce(
    (acc, color) => ({
      ...acc,
      [color]: toColor,
    }),
    {} as LinkedColors,
  );

  return colorPaletteToUpdate;
};

export const getPageComponentsColors = (
  editorAPI: EditorAPI,
  pageId: string,
) => {
  const allComponents: CompRef[] =
    editorAPI.components.getAllComponents(pageId);

  let colors: ColorName[] = [];

  for (const compRef of allComponents) {
    const componentType = editorAPI.components.getType(compRef);
    for (const compFunc of PRESETS) {
      const compData = compFunc.getApi(editorAPI).get(componentType);
      if (!_.isEmpty(compData)) {
        colors = [
          ...scanPathsAndGetColors(compData, compFunc.colorPaths, colors),
        ];
      }
    }
  }

  return colors;
};
