import type { EditorAPI } from '@/editorAPI';
import _ from 'lodash';
import { syncColorsFlow as fedopsSynColorsFlow } from './fedops';
import { biLogger } from '@/util';
import {
  syncNewColorPaletteStart,
  syncNewColorPaletteFinish,
  themeFromLogoError,
} from '@wix/bi-logger-editor/v2';
import { saveSiteColorsSyncFlag } from './utils';
import type { ColorName, ColorPalette } from '../colors';
import { ErrorReporter } from '@wix/editor-error-reporter';

/**
 * Specific migration to add support for TPA/widgets that created with new color palette and use in old CP sites
 * https://jira.wixpress.com/browse/WEED-27699
 */
const NEW_TO_OLD_COLORS_INDEXES_MAP = {
  MAIN_1: [36, 11],
  MAIN_2: [37, 15],
  SHADE_1: [38, 12],
  SHADE_2: [39, 13],
  SHADE_3: [40, 14],
  SECONDARY_1: [41, 18],
  TITLE: [45, 15],
  SUBTITLE: [46, 15],
  LINE: [47, 12],
  PRIMARY_BUTTON_FILL: [48, 18],
  PRIMARY_BUTTON_BORDER: [49, 18],
  PRIMARY_BUTTON_TEXT: [50, 11],
  PRIMARY_BUTTON_FILL_HOVER: [51, 11],
  PRIMARY_BUTTON_BORDER_HOVER: [52, 18],
  PRIMARY_BUTTON_TEXT_HOVER: [53, 18],
  PRIMARY_BUTTON_FILL_DISABLED: [54, 13],
  PRIMARY_BUTTON_BORDER_DISABLED: [55, 13],
  PRIMARY_BUTTON_TEXT_DISABLED: [56, 11],
  SECONDARY_BUTTON_FILL: [57, 11],
  SECONDARY_BUTTON_BORDER: [58, 18],
  SECONDARY_BUTTON_TEXT: [59, 18],
  SECONDARY_BUTTON_FILL_HOVER: [60, 18],
  SECONDARY_BUTTON_BORDER_HOVER: [61, 18],
  SECONDARY_BUTTON_TEXT_HOVER: [62, 11],
  SECONDARY_BUTTON_FILL_DISABLED: [63, 11],
  SECONDARY_BUTTON_BORDER_DISABLED: [64, 13],
  SECONDARY_BUTTON_TEXT_DISABLED: [65, 13],
};

export const runSyncColorsMigration = async (editorAPI: EditorAPI) => {
  try {
    fedopsSynColorsFlow.paletteMigration.start();
    biLogger.report(syncNewColorPaletteStart({ action: 'proceed' }));
    const newPalette: ColorPalette = {};

    _.forIn(NEW_TO_OLD_COLORS_INDEXES_MAP, (indexes) => {
      const [newIndex, oldIndex] = indexes;
      const key = `color_${newIndex}` as ColorName;
      newPalette[key] = `color_${oldIndex}` as ColorName;
    });

    editorAPI.theme.colors.update(newPalette);

    await editorAPI.waitForChangesAppliedAsync();
    saveSiteColorsSyncFlag(editorAPI);
    fedopsSynColorsFlow.paletteMigration.end();
    biLogger.report(syncNewColorPaletteFinish({ action: 'proceed' }));
  } catch (e: MaybeError) {
    biLogger.report(themeFromLogoError({ origin: 'syncNewColorPalette' }));
    ErrorReporter.captureException(e, {
      tags: { syncNewColorPalette: true },
    });
  }
};
