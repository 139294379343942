import _ from 'lodash';
import type { CompData } from 'types/documentServices';
import experiment from 'experiment';

import {
  COLOR_ROLES,
  getColorValueByRole,
  getColorNameByRole,
  type ColorPalette,
  type ColorName,
  COLOR_REGEX,
  type ColorMatch,
} from '../../colors';
import type { ButtonPathLists } from './runners/config/getButtonPathPriorityLists';

export const wrapColorWithClassAttr = (color: string) => {
  return `class="${color}"`;
};

export const wrapColorWithClassAtributeByPropertyType = (
  colorMatchObj: ColorMatch,
) => {
  console.log(`class="${colorMatchObj.propertyToUpdateName}"`);
  return `class="${colorMatchObj.propertyToUpdateName}"`;
};

export type ColorReplacer = (colorMatch: string) => string;
/**
 * Recursively replaces all color values in style props
 */
const replaceColorsInStyleProps = (
  props: any,
  getNewColorValue: ColorReplacer,
  searchValue: string | RegExp,
): any => {
  if (experiment.isOpen('se_fixProGalleryStylesIssue')) {
    if (_.isEmpty(props)) {
      return props;
    }
  }
  if (typeof props === 'object') {
    const mapFunction = (innerProps: any) =>
      replaceColorsInStyleProps(innerProps, getNewColorValue, searchValue);

    return Array.isArray(props)
      ? props.map(mapFunction)
      : _.mapValues(props, mapFunction);
  }
  if (typeof props === 'string') {
    return props.replaceAll(searchValue, getNewColorValue);
  }
  return props;
};

const setNewColorInStyleData = (
  styleData: CompData,
  path: string,
  colorRole: COLOR_ROLES,
) => {
  _.set(styleData, path, getColorNameByRole(colorRole));
};

export const updateDataInPropertiesSource = (styleData: CompData) => {
  const propertiesObj = styleData.style.properties;
  const propertiesSrcObj = styleData.style.propertiesSource;

  if (propertiesSrcObj) {
    _.forOwn(propertiesSrcObj, (value, key) => {
      if (value === 'value' && COLOR_REGEX.test(propertiesObj[key])) {
        _.set(propertiesSrcObj, key, 'theme');
      }
    });
  }
};

const updateButtonsColorsInStyleData = (
  styleData: CompData,
  allColors: ColorPalette,
  buttonPathLists: ButtonPathLists[],
) => {
  const getColorFromStyleData = (path: string) => {
    // some colors could be stored as 'color_[number]', some as hex
    return (
      allColors[_.get(styleData, path) as ColorName] || _.get(styleData, path)
    );
  };

  for (const group of buttonPathLists) {
    const [bgButtonPath, borderButtonPath, textButtonPath] = Object.keys(
      group,
    ) as string[];
    const [fillButtonRole, borderButtonRole, textButtonRole] = Object.values(
      group,
    ) as COLOR_ROLES[];

    const isButtonBgColorsEqual =
      getColorValueByRole(allColors, fillButtonRole) ===
      getColorFromStyleData(bgButtonPath);
    const isButtonTextColorsEqual =
      getColorValueByRole(allColors, textButtonRole) ===
      getColorFromStyleData(textButtonPath);
    const isButtonBorderColorsEqual =
      getColorValueByRole(allColors, borderButtonRole) ===
      getColorFromStyleData(borderButtonPath);

    // wire button colors only if button BG and button text
    // are from same buttons group (Primary, Secondary, Primary-Hover, Secondary-Hover, Primary-Disabled, Secondary-Disabled)
    if (isButtonBgColorsEqual && isButtonTextColorsEqual) {
      setNewColorInStyleData(styleData, bgButtonPath, fillButtonRole);
      setNewColorInStyleData(styleData, textButtonPath, textButtonRole);
      if (isButtonBorderColorsEqual) {
        setNewColorInStyleData(styleData, borderButtonPath, borderButtonRole);
      }
    }
  }

  updateDataInPropertiesSource(styleData);
};
const getThemePropertiesSource = (
  propertiesSource: Record<string, 'value' | 'theme'>,
) => {
  const themedPropperties: string[] = [];
  _.forIn(propertiesSource, (v, k) => {
    if (v === 'theme') {
      themedPropperties.push(k);
    }
  });
  return themedPropperties;
};

export {
  replaceColorsInStyleProps,
  updateButtonsColorsInStyleData,
  getThemePropertiesSource,
};
