import {
  autowritingStart,
  autowritingSuccess,
  autowritingFailed,
} from '@wix/bi-logger-editor/v2';
import { biLogger } from '@/util';
import { runAutoWiring } from './runners/runAutoWiring';
import type { EditorAPI } from '@/editorAPI';

const WIRING_HISTORY_LABEL = 'colorsAutowiringStart';

export const applyAutoWiring = async (editorAPI: EditorAPI) => {
  biLogger.report(autowritingStart({}));

  const startTime = Date.now();

  editorAPI.history.add(WIRING_HISTORY_LABEL);

  try {
    editorAPI.savePublish.lockSavePublish();

    await runAutoWiring(editorAPI);

    editorAPI.savePublish.unlockSavePublish();

    if (!editorAPI.dsRead.generalInfo.isDraft()) {
      await new Promise((...handlers) => {
        editorAPI.saveManager.saveInBackground(...handlers, 'colorsAutowiring');
      });
    }
  } catch (e: any) {
    const timeUntilError = Date.now() - startTime;

    editorAPI.history.performUndoUntilLabel(WIRING_HISTORY_LABEL, true);

    editorAPI.savePublish.unlockSavePublish();

    console.error('[Colors autowiring]:', e);

    biLogger.report(
      autowritingFailed({
        timeUntilError,
        errorMessage: e?.message,
      }),
    );
    return;
  }

  biLogger.report(autowritingSuccess({ duration: Date.now() - startTime }));
};
