// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import { fontUtils } from '@/util';
import {
  PALETTE_SEQUENCES_MATRIX,
  PALETTE_SEQUENCES_MATRIX_INDEXES,
} from '../colors/constants';

function isJSON(string) {
  try {
    const obj = JSON.parse(string);
    return _.isPlainObject(obj);
  } catch (e) {
    return false;
  }
}

function applyThemeFonts(value, fonts) {
  if (fonts[value]) {
    return fonts[value];
  }
  return Object.entries(fonts).reduce(
    (_value, [fontKey, fontValue]) =>
      _.isString(_value) ? _value.replace(`[${fontKey}]`, fontValue) : _value,
    value,
  );
}

/**
 * Replace value(site_x_y) with actual color value in css string
 * background: value(site_1_1); --> background: #FFFFFF;
 */
const replaceCssSiteKeysWithColor = (
  value: string,
  colors: Record<string, string>,
) =>
  PALETTE_SEQUENCES_MATRIX_INDEXES.reduce((acc, [x, y]) => {
    return acc.replace(
      new RegExp(`value\\(site\\_${x + 1}\\_${y + 1}\\)`, 'g'),
      colors[PALETTE_SEQUENCES_MATRIX[x][y]],
    );
  }, value);

function applyThemeColors(value, colors) {
  if (colors[value]) {
    return colors[value];
  }

  let resultVal = Object.entries(colors).reduce(
    (_value, [colorKey, colorValue]) =>
      _.isString(_value) ? _value.replace(`{${colorKey}}`, colorValue) : _value,
    value,
  );

  resultVal = _.isString(resultVal)
    ? replaceCssSiteKeysWithColor(resultVal, colors)
    : resultVal;

  return resultVal;
}

function processValue(value, theme) {
  if (_.isPlainObject(value)) {
    return processObject(value, theme);
  }

  if (Array.isArray(value)) {
    return value.map((v) => processValue(v, theme));
  }

  if (isJSON(value)) {
    const object = JSON.parse(value);
    return JSON.stringify(processObject(object, theme));
  }

  const withFonts = applyThemeFonts(value, theme.fonts);
  const withColors = applyThemeColors(withFonts, theme.colors);

  return withColors;
}

function processObject(obj, theme) {
  return _.mapValues(obj, (value) => processValue(value, theme));
}

/**
 *
 * @param {Object} fontObj - Contains all styles related to font(weight, variation, size other)
 * @param {string} fontObj.style - Font style
 * @param {string} fontObj.variant - Font variant
 * @param {string} fontObj.weight - Font weight
 * @param {string} fontObj.size - Font size
 * @param {string} fontObj.lineHeight - Line height
 * @param {string} fontObj.family - Font family
 * @param {string} fontObj.color - Color name from theme e.g. {color_15}
 * @param {boolean} fontObj.bold - is Bold
 * @param {boolean} fontObj.italic - is Bold
 * @param {boolean} fontObj.fontWithFallbacks - is Bold
 * @param {boolean} fontObj.cssColor - Font color HEX or RGB
 * @param {string} [fontObj.letterSpacing] - Optional Letter Spacing
 *
 * @returns {Map<string, string>} - parsed font styles from theme
 */
const normalizeFontStyles = (fontObj) => {
  const mapArr = [
    ['fontSize', fontObj.size],
    ['fontFamily', fontObj.family],
    ['fontWeight', fontObj.weight],
    ['fontStyle', fontObj.style],
    ['fontVariant', fontObj.variant],
    ['color', fontObj.cssColor],
    [
      'letterSpacing',
      fontObj.letterSpacing || constants.TEXT_CONTROLS.DEFAULT_LETTER_SPACING,
    ],
  ];
  return new Map(mapArr);
};

/**
 *
 * @param {Array} properties - Style properties
 * @param {Array} values - Style values
 * @param {string} content - Inner content of copied element
 */
const createFontSpan = (properties, values, content) => {
  const [styleName, ...restProps] = properties;
  const [value, ...restValues] = values;

  const element = window.document.createElement('span');
  element.style[styleName] = value;

  if (properties.length > 1) {
    element.appendChild(createFontSpan(restProps, restValues, content));
    return element;
  }
  element.innerHTML = content;
  return element;
};

/**
 * Function wraps origin content of copied element by
 * spans fith inline font styles to copy styles from original theme
 * @param {string} content - origin content of element
 * @param {Map} fontProps - style name to style value map
 * @returns {string} - contains original parent tag and wrapped content by spans for every font property
 */
const wrapContent = (content, fontProps) => {
  const tmpContainer = window.document.createElement('div');
  tmpContainer.innerHTML = content;

  Array.from(tmpContainer.children).forEach((contentEl) => {
    const contentElHtml = contentEl ? contentEl.innerHTML : '';
    const wrappedContent = createFontSpan(
      [...fontProps.keys()],
      [...fontProps.values()],
      contentElHtml,
    );
    contentEl.innerHTML = '';
    contentEl.appendChild(wrappedContent);

    return contentEl;
  });

  return tmpContainer.innerHTML;
};

function applyThemeToData(data, theme) {
  if (data.type !== 'StyledText') {
    return data;
  }
  const classNameRegExp =
    /(?:style="([/():;.a-z0-9 _-]+)"\s)?(class="((font|color)_\d+)")(?:\sstyle="([/():;.a-z0-9 _-]+)")?/gi;
  let fontStyles;

  let text = data.text.replace(
    classNameRegExp,
    (
      regMatch,
      inlineStyleBefore,
      className,
      themeVariable,
      styleKey,
      inlineStyleAfter,
    ) => {
      const additionalInlineStyle = inlineStyleBefore || inlineStyleAfter || '';
      let inlineStyles = '';
      if (additionalInlineStyle) {
        inlineStyles += `${additionalInlineStyle}`;
      }

      if (styleKey === 'font') {
        fontStyles = fontUtils.parseStyleFont(
          themeVariable,
          theme.fonts,
          theme.colors,
        );
      }

      if (styleKey === 'color') {
        inlineStyles += `color:${theme.colors[themeVariable]}`;
      }

      const textTheme = theme.textThemes && theme.textThemes[themeVariable];

      if (!inlineStyles.includes('line-height')) {
        if (inlineStyles.length && inlineStyles.slice(-1) !== ';') {
          inlineStyles += ';';
        }

        inlineStyles += `line-height:${
          textTheme
            ? fontStyles.lineHeight
            : constants.TEXT_CONTROLS.DEFAULT_LINE_HEIGHT
        };`;
      }

      if (textTheme) {
        fontStyles.letterSpacing = textTheme.letterSpacing;
      }

      return `${className} style="${inlineStyles}"`;
    },
  );

  if (fontStyles) {
    text = wrapContent(text, normalizeFontStyles(fontStyles));
  }
  // Replace origin content by normalised content with nested spans for font styles
  return _.merge({}, data, { text });
}

function applyThemeToStyle(style, theme) {
  const styleObj = _.isString(style) ? theme.styles[style] : style;

  if (!styleObj || !styleObj.style) {
    return;
  }

  return _.merge({}, styleObj, {
    style: {
      properties: processObject(styleObj.style.properties, theme),
      propertiesSource: _.mapValues(
        styleObj.style.propertiesSource,
        () => 'value',
      ),
    },
  });
}

function applyThemeToDesign(design, theme) {
  return processObject(design, theme);
}

/**
 * Component style can have theme references for colors, fonts and styles. This method replaces all references to values from theme.
 * Processes nested structures, and even JSON values.
 * @param {Object} component - serialized component structure
 * @param {Object} theme - serialized theme, prepared for copy-paste flow. See getThemeData function in EditorAPI
 * @returns {Object} component - serialized component, with all theme references (colors, fonts, styles)
 */
function applyThemeToSerializedComponents(component, theme) {
  // TODO: make argument type only array (https://github.com/wix-private/santa-editor/pull/23195/files#r361124462)
  if (Array.isArray(component)) {
    return component.map((c) => applyThemeToSerializedComponents(c, theme));
  }

  return _.merge({}, component, {
    data: component.data ? applyThemeToData(component.data, theme) : undefined,
    design: component.design
      ? applyThemeToDesign(component.design, theme)
      : undefined,
    style: component.style
      ? applyThemeToStyle(component.style, theme)
      : undefined,
    components: component.components
      ? applyThemeToSerializedComponents(component.components, theme)
      : undefined,
  });
}

export { applyThemeToSerializedComponents, applyThemeToStyle };
